<template>
  <div>
    <div v-if="!uploading" class="file-upload">
      <input class="d-none" :id="fileUploadId" type="file" ref="file" v-on:change="uploadFile()"/>
    </div>
    <div v-if="uploading">Uploading File... <img alt="spinner" src="/img/spinner.gif" style="width:50px;"/></div>
    <div v-if="errors.length>0">
      <p v-for="error in errors" v-bind:key="error" class="text-danger">{{error}}</p>
    </div>
  </div>
</template>
<script>
  import ApiProperties from '../../../src/main/resources/application';
  import filesize from 'filesize';
  import get from 'just-safe-get'

  export default {
    props: {
      actionItem: {},
      fileUploadId: String
    },

    data: function() {
      return {
        file: '',
        errors: [],
        uploading: false
      }
    },

    methods: {
      checkFile: function(file) {
        if (!file) {
          this.errors.push('No file is selected.');
          return false;
        }

        if (file.size > this.$root.properties.uploadFileSizeLimit) {
          this.errors.push(`File size (${filesize(file.size)}) exceeds allowed limit of ${filesize(this.$root.properties.uploadFileSizeLimit)}`);
          return false;
        }

        return true;
      },

      uploadFile: function() {
        //clear errors
        this.errors = []

        this.file = this.$refs.file.files[0];
        if (!this.checkFile(this.file)) {
          return;
        }

        this.uploading=true;

        let formData = new FormData();
        formData.append('file', this.file);

        let config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          pathVars: {
            itemId: this.actionItem.id
          }
        };

        this.$http.post(ApiProperties.api.todoUploadByItemId, formData, config).then(response => {
          this.$root.$emit('StateChange', response.data);

          this.uploading = false;
        }).catch(e => {
          this.uploading=false;
          let message = get(e, 'response.data.message')
          this.errors.push(message || e);
        });
      }
    }
  }
</script>